import toast from "react-hot-toast";
import { toastOptions } from "../helpers/functionHelpers";

export const handleAliExpressError = (errorResponse) => {
  const errorMsg =
    errorResponse?.data?.aliexpress_ds_order_create_response?.result;
  if (errorMsg?.is_success === false) {
    if (errorMsg.error_msg) {
      toast.error(errorMsg.error_msg, toastOptions);
    } else if (errorMsg.error_code === "DELIVERY_METHOD_NOT_EXIST") {
      toast.error(
        "DELIVERY FOR THIS PRODUCT NOT AVAILABLE TO YOUR ADDRESS",
        toastOptions
      );
    } else {
      toast.error("PRODUCT OUT OF STOCK", toastOptions);
    }
    return true;
  }
  return false;
};
