import toast from "react-hot-toast";
import { toastOptions } from "../helpers/functionHelpers";

export const getLocationDetails = async (address) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    );
    const data = await response.json();

    if (data.status !== "OK") {
      toast.error(
        "Invalid address. Please enter a valid address from the suggestions.",
        toastOptions
      );
      return;
    }

    const addressComponents = data.results[0].address_components;
    const locationDetails = {
      streetNo: "",
      home: "",
      street: "",
      city: "",
      state: "",
      country: "",
      countyShort: "",
      zipCode: "",
    };

    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes("street_number"))
        locationDetails.streetNo = component.long_name;
      if (types.includes("route")) locationDetails.home = component.long_name;
      if (types.includes("neighborhood"))
        locationDetails.street = component.long_name;
      if (types.includes("administrative_area_level_2"))
        locationDetails.city = component.long_name;
      if (types.includes("administrative_area_level_1"))
        locationDetails.state = component.long_name;
      if (types.includes("country"))
        locationDetails.country = component.long_name;
      if (types.includes("country"))
        locationDetails.countyShort = component.short_name;
      if (types.includes("postal_code"))
        locationDetails.zipCode = component.long_name;
    });

    return locationDetails;
  } catch (error) {
    toast.error(
      "Invalid address. Please enter a valid address from the suggestions.",
      toastOptions
    );
  }
};
