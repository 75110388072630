export const getErrorMessages = (user, userDetails, paymentTerm) => [
  { condition: !user?.address, message: "Please enter delivery address." },
  {
    condition:
      !userDetails.shippingType || userDetails.shippingType === "Select",
    message: "Please select mode of delivery.",
  },
  {
    condition: !userDetails.incoterm || userDetails.incoterm === "Select",
    message: "Please select incoterm.",
  },
  {
    condition: userDetails.incoterm !== "LOCAL_DELIVERY" && !userDetails.port,
    message: "Please enter port.",
  },
  {
    condition: !paymentTerm || paymentTerm === "Select payment method",
    message: "Please select payment method.",
  },
];
