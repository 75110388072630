import { numberWithCommas } from "./functionHelpers";

export const createInvoice = (cart, convertCurrency, selectedCurrency) =>
  cart?.map((product) => ({
    name: product?.productName,
    image:
      product?.imgUrl ||
      (Array.isArray(product?.productImages) &&
        product?.productImages[0]?.image),
    quantity: product?.quantity,
    price: numberWithCommas(
      convertCurrency(
        product?.cost || product?.totalPrice,
        product?.currency || "USD",
        selectedCurrency
      )
    ),
  }));
