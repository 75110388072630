import React, { useContext, useEffect, useState } from "react";

import "./AliexpressProductInfo.css";

import { useNavigate, useParams } from "react-router-dom";
import { useGetAliexpressProductInfo } from "../../components/hooks/useGetAliexpressProductInfo";
import Footer from "../../components/Footer/Footer";
import toast, { Toaster } from "react-hot-toast";
import {
  numberWithCommas,
  toastOptions,
} from "../../components/helpers/functionHelpers";
import { useTranslation } from "react-i18next";
import {
  fiveStar,
  fourStar,
  noStar,
  oneStar,
  threeStar,
  twoStar,
} from "../../components/constants";
import { AliexpressCartContext } from "../../components/utils/AliexpressCartState";
import { GlobalContext } from "../../components/utils/GlobalState";
import CardSkeleton from "../../components/SkeletonLoader/CardSkeleton";
import AliLogo from "../../assets/img/ali-logo.png";
import NewHeader from "../../components/NewHeader/NewHeader";
import Properties from "./Properties";

const AliexpressProductInfo = () => {
  const { productId } = useParams();
  const { cart, setCart, setRerenderCart } = useContext(AliexpressCartContext);
  const { user } = useContext(GlobalContext);

  const [currentImage, setCurrentImage] = useState("");
  const [loadCart, setLoadCart] = useState();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    getInfo,
    loading,
    productInfo,
    productImages,
    currentItem,
    setCurrentItem,
  } = useGetAliexpressProductInfo();

  const addToCart = async (product, stock) => {
    if (!user) {
      return navigate("/login");
    }
    if (stock < 1 || !stock) {
      toast.error("Product is out of stock", toastOptions);
      return;
    }
    setLoadCart(true);
    await setCart((prevCart) => {
      const itemInCart = prevCart.find(
        (item) => item.productId === product.productId
      );
      if (itemInCart?.quantity > stock) {
        toast.error("Stock limit exceeded", toastOptions);
        return prevCart;
      }
      setRerenderCart((prev) => !prev);
      if (itemInCart) {
        toast.success("Product added to cart", toastOptions);
        return prevCart.map((item) =>
          item.productId === product.productId
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }
      toast.success("Product added to cart", toastOptions);
      return [...prevCart, { ...product, quantity: 1 }];
    });
    setLoadCart(false);
  };

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  useEffect(() => {
    getInfo(productId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  useEffect(() => {
    if (productImages?.length > 0) {
      setCurrentImage(productImages[0]);
    }
  }, [productImages]);

  const displayImageHandler = (image) => {
    setCurrentImage(image);
  };

  return (
    <div>
      {" "}
      <Toaster />
      <NewHeader />
      <div className={loadCart ? "dim-loader" : ""}></div>
      {loading ? (
        <CardSkeleton />
      ) : (
        <section id="product-listing">
          <div className="container">
            <div className="row mb-2"></div>

            <div className="row mb-5">
              <div className="col-lg-5">
                <div className="mp-wrap">
                  <div className="main-product-pic">
                    <img src={currentImage} className="" alt="..." />
                  </div>
                  <div className="product-thumbnail">
                    <div className="d-flex justify-content-start flex-wrap">
                      {productImages?.slice(0, 5).map((image, index) => (
                        <div
                          className="pt-box"
                          onClick={() => displayImageHandler(image)}
                          key={index}
                        >
                          <img src={image} className="" alt="..." />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 row ">
                <div className="col-lg-6">
                  <div className="product-detail-ctn">
                    <p className="product-price">
                      <span className="p-currency">
                        {
                          currentItem?.ae_sku_property_dtos
                            ?.ae_sku_property_d_t_o[0]?.sku_property_name
                        }
                        :
                      </span>{" "}
                      <span className="p-unit">
                        {
                          currentItem?.ae_sku_property_dtos
                            ?.ae_sku_property_d_t_o[0]?.sku_property_value
                        }
                      </span>
                    </p>
                    <p className="product-price">
                      <span className="p-currency">
                        {currentItem?.currency_code}
                      </span>{" "}
                      {currentItem?.offer_sale_price &&
                        numberWithCommas(currentItem?.offer_sale_price)}{" "}
                      <span className="p-unit">
                        {/* / {productInfo?.unitForMinOrder} */}
                      </span>
                    </p>
                    <p className="discount__price">
                      {currentItem?.currency_code}{" "}
                      {currentItem?.sku_price &&
                        numberWithCommas(currentItem?.sku_price)}{" "}
                    </p>
                    <p className="product-price">
                      <span className="p-currency">Sold:</span>{" "}
                      <span className="p-unit">
                        {productInfo?.ae_item_base_info_dto?.sales_count}
                      </span>
                    </p>
                    <p className="product-price">
                      <span className="p-currency">Available stock:</span>{" "}
                      <span className="p-unit">
                        {currentItem?.sku_available_stock &&
                          numberWithCommas(
                            currentItem?.sku_available_stock
                          )}{" "}
                      </span>
                    </p>

                    <p className="product-price ml-2">
                      <span className="p-currency">{t("Stock")}:</span>{" "}
                      <span className="p-unit">
                        {currentItem?.sku_stock ? "In stock" : "Out of stock"}
                      </span>
                    </p>
                  </div>
                  <div className="review-star-wrapper">
                    <div className="reviews-star-ctn">
                      {productInfo?.ae_item_base_info_dto
                        ?.avg_evaluation_rating < 1
                        ? noStar.map((star, index) => (
                            <img src={star} alt="star" key={index} />
                          ))
                        : productInfo?.ae_item_base_info_dto
                            ?.avg_evaluation_rating < 2 &&
                          productInfo?.ae_item_base_info_dto
                            ?.avg_evaluation_rating > 0
                        ? oneStar.map((star, index) => (
                            <img src={star} alt="star" key={index} />
                          ))
                        : productInfo?.ae_item_base_info_dto
                            ?.avg_evaluation_rating < 3 &&
                          productInfo?.ae_item_base_info_dto
                            ?.avg_evaluation_rating > 1
                        ? twoStar.map((star, index) => (
                            <img src={star} alt="star" key={index} />
                          ))
                        : productInfo?.ae_item_base_info_dto
                            ?.avg_evaluation_rating < 4 &&
                          productInfo?.ae_item_base_info_dto
                            ?.avg_evaluation_rating > 2
                        ? threeStar.map((star, index) => (
                            <img src={star} alt="star" key={index} />
                          ))
                        : productInfo?.ae_item_base_info_dto
                            ?.avg_evaluation_rating < 5 &&
                          productInfo?.ae_item_base_info_dto
                            ?.avg_evaluation_rating > 3
                        ? fourStar.map((star, index) => (
                            <img src={star} alt="star" key={index} />
                          ))
                        : productInfo?.ae_item_base_info_dto
                            ?.avg_evaluation_rating < 6 &&
                          productInfo?.ae_item_base_info_dto
                            ?.avg_evaluation_rating > 4
                        ? fiveStar.map((star, index) => (
                            <img src={star} alt="star" key={index} />
                          ))
                        : null}
                    </div>

                    <p className="product-specification-type m-0">
                      {
                        productInfo?.ae_item_base_info_dto
                          ?.avg_evaluation_rating
                      }{" "}
                      rating
                    </p>
                  </div>
                  <div className="current_item_wrapper">
                    {productInfo?.ae_item_sku_info_dtos?.ae_item_sku_info_d_t_o?.map(
                      (item, index) => (
                        <div
                          className="current_item_ctn"
                          onClick={() => {
                            setCurrentItem(item);
                            displayImageHandler(
                              item?.ae_sku_property_dtos
                                ?.ae_sku_property_d_t_o[0]?.sku_image
                            );
                          }}
                          key={index}
                        >
                          {item?.ae_sku_property_dtos?.ae_sku_property_d_t_o[0]
                            ?.sku_image && (
                            <img
                              src={
                                item?.ae_sku_property_dtos
                                  ?.ae_sku_property_d_t_o[0]?.sku_image
                              }
                              alt="product"
                            />
                          )}
                        </div>
                      )
                    )}
                  </div>
                  <Properties
                    productInfo={productInfo}
                    currentItem={currentItem}
                    setCurrentItem={setCurrentItem}
                  />
                </div>
                <div className="col-lg-6">
                  <div className="sellers-profile-ctn">
                    <div className="sellers-img-ctn ">
                      <img src={AliLogo} alt="aliexpress logo" />
                    </div>
                    <div className="sellers-company my-4">
                      <h2>{productInfo?.ae_store_info?.store_name}</h2>
                      <p>ALIEXPRESS</p>
                    </div>
                    <div className="seller-message-ctn">
                      <button
                        id="add_cart_button"
                        onClick={() =>
                          addToCart(
                            {
                              logistic: "",
                              shipping: null,
                              freeShipping: null,
                              shippingFee: null,
                              minDeliveryDuration: null,
                              maxDeliveryDuration: null,
                              source: productInfo?.ae_store_info
                                ?.store_country_code
                                ? productInfo?.ae_store_info?.store_country_code
                                : "CN",
                              sku_attr: currentItem?.sku_attr,
                              sku_id: currentItem?.sku_id,
                              productId: productId,
                              cost: currentItem?.offer_sale_price,
                              currency: currentItem?.currency_code,
                              productName:
                                productInfo?.ae_item_base_info_dto?.subject,
                              imgUrl: currentItem?.ae_sku_property_dtos
                                ?.ae_sku_property_d_t_o[0]?.sku_image
                                ? currentItem?.ae_sku_property_dtos
                                    ?.ae_sku_property_d_t_o[0]?.sku_image
                                : currentImage,
                            },
                            currentItem?.sku_available_stock
                          )
                        }
                      >
                        {t("Add_to_cart")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-description-ctn">
              {/* <div dangerouslySetInnerHTML={{ __html: details }} /> */}

              <div className="product-description-wrapper d-flex justify-content-start">
                <div className="production-specification-title active">
                  <h2>{t("Product_Specifications")}</h2>
                </div>
              </div>
              <section id="product-description">
                <div className="row mb-4">
                  <div className="col-lg-12">
                    <div className="m-product-spec-box">
                      <table className="table table-striped">
                        <tbody>
                          <tr>
                            <td className="mps-title">Poduct name:</td>
                            <td>
                              {" "}
                              {productInfo?.ae_item_base_info_dto?.subject}
                            </td>
                          </tr>
                          {productInfo?.ae_item_properties?.ae_item_property?.map(
                            (specification, index) => (
                              <tr key={index}>
                                <td className="mps-title">
                                  {specification?.attr_name}:
                                </td>
                                <td> {specification?.attr_value}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      )}
      <Footer />
    </div>
  );
};

export default AliexpressProductInfo;
