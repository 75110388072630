import { useState } from "react";
import { axiosInstance } from "../baseUrl";

export const useSearchAliexpressProducts = () => {
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedAliexpressProduct, setSearchedAliexpressProducts] = useState(
    []
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const getSearchedAliexpressProducts = async (search) => {
    try {
      const { data } = await axiosInstance.get(
        `/ali-express/products/search?query=${search}&page=${currentPage}
        `
      );
      setTotalPages(data?.totalPages);
      setTotalCount(data?.totalCount);
      setSearchedAliexpressProducts(data?.products);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error searching aliexpress products", error);
    }
  };

  return {
    totalCount,
    currentPage,
    handlePageChange,
    isLoading,
    totalPages,
    searchedAliexpressProduct,
    getSearchedAliexpressProducts,
  };
};
